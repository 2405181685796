<template>
  <pz-form-flujo
    v-model="formulario2['captcha']"
    :available-captcha="extraData.withCaptcha"
    :forward="handleSave"
    :backwards="showBack?handleBack:null"
  >
    <span slot="form_title">
      {{ $t('forms_flujo.form2_persona.SP.title') }}
    </span>
    <el-form
      slot="formulario"
      :ref="reference"
      label-position="top"
      :model="formulario2"
      status-icon
      :rules="rules_formulario2"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="tipo_vivienda"
            :label="$t('forms_flujo.form2_persona.SP.property_type')"
            :error="errors.get('tipo_vivienda')"
          >
            <el-select
              v-model="formulario2.tipo_vivienda"
              clearable
              :placeholder="$t('forms_flujo.form2_persona.SP.property_type')"
            >
              <el-option
                v-for="item in properties"
                :key="item.id"
                :label="item.tipo"
                :value="item.id"
                :placeholder="$t('forms_flujo.form2_persona.property_type')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="relacion_laboral_id"
            :label="$t('forms_flujo.form2_persona.SP.employee_type')"
            :error="errors.get('relacion_laboral_id')"
          >
            <el-select
              v-model="formulario2.relacion_laboral_id"
              clearable
              :placeholder="$t('forms_flujo.form2_persona.SP.employee_type')"
            >
              <el-option
                v-for="item in employment_relations"
                :key="item.id"
                :label="item.relacion"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="cash_in"
            :label="$t('forms_flujo.form2_persona.SP.cash_in')"
            :error="errors.get('cash_in')"
          >
            <el-select
              v-model="formulario2.cash_in"
              clearable
              :placeholder="$t('forms_flujo.form2_persona.SP.cash_in')"
            >
              <el-option
                v-for="item in cahs_in_list"
                :key="item.id"
                :label="item.nombre"
                :value="item.id"
                :placeholder="$t('forms_flujo.form2_persona.SP.cash_in')"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="comoseentero"
            :label="$t('forms_flujo.form2_persona.UY.how_find_out')"
            :error="errors.get('comoseentero')"
          >
            <el-select
              v-model="formulario2.comoseentero"
              clearable
              :placeholder="$t('forms_flujo.form2_persona.UY.how_find_out')"
            >
              <el-option
                v-for="item in formas_de_enterarse"
                :key="item.id"
                :label="item.forma"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.fuente_ingreso_principal"
            :label="$t('forms_flujo.form2_persona.SP.fuente_ingreso_principal')"
            :error="errors.get('fuente_ingreso_principal')"
          >
            <el-select
              ref="info_extra.fuente_ingreso_principal"
              v-model="formulario2.info_extra.fuente_ingreso_principal"
              clearable
              :placeholder="$t('forms_flujo.form2_persona.SP.fuente_ingreso_principal')"
            >
              <el-option
                v-for="item in income_source"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :placeholder="$t('forms_flujo.form2_persona.SP.fuente_ingreso_principal')"
              />
            </el-select>
            <!--<el-autocomplete-->
            <!--v-model="formulario2.info_extra.fuente_ingreso_principal"-->
            <!--:fetch-suggestions="getIncomen"-->
            <!--:placeholder="$t('forms_flujo.form2_persona.SP.fuente_ingreso_principal')"-->
            <!--/>-->
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.referenciado_por"
            :label="$t('forms_flujo.form2_persona.SP.referenciado_por')"
            :error="errors.get('referenciado_por')"
          >
            <el-input
              v-model="formulario2.info_extra.referenciado_por"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.SP.referenciado_por')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <hr class="pz-dotted">
      <el-row
        :gutter="20"
        class="pz-large-margin"
      >
        <el-col
          :span="24"
          class="row justify-content-center"
        >
          <b class="pz-font-size-lg">
            {{ $t('forms_flujo.form2_persona.SP.contact.title') }}
          </b>
        </el-col>
        <el-col
          :span="24"
          class="row justify-content-center"
        >
          <label>
            {{ $t('forms_flujo.form2_persona.SP.contact.sub_title') }}
          </label>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="persona_contacto"
            :label="$t('forms_flujo.form2_persona.SP.contact.full_name')"
            :error="errors.get('persona_contacto')"
          >
            <el-input
              v-model="formulario2.persona_contacto"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.SP.contact.full_name')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="telefono_persona_contacto"
            :label="$t('forms_flujo.form2_persona.SP.contact.phone')"
            :error="errors.get('telefono_persona_contacto')"
          >
            <el-input
              v-model="formulario2.telefono_persona_contacto"
              :type="'text'"
              auto-complete="no"
              :placeholder="$t('forms_flujo.form2_persona.SP.contact.phone')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="email_contacto"
            :label="$t('forms_flujo.form2_persona.SP.email_contacto')"
            :error="errors.get('email_contacto')"
          >
            <el-input
              ref="email_contacto"
              v-model="formulario2.email_contacto"
              :type="'text'"
              auto-complete="on"
              :placeholder="$t('forms_flujo.form2_persona.SP.email_contacto')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.acepted_before"
            :error="errors.get('accepted_before')"
          >
            <el-switch
              v-model="formulario2.info_extra.accepted_before"
              :active-text="$t('forms_flujo.form1_persona.SP.acepted_before')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="info_extra.has_others_credits"
            :error="errors.get('has_others_credits')"
          >
            <el-switch
              v-model="formulario2.info_extra.has_others_credits"
              :active-text="$t('forms_flujo.form1_persona.SP.has_others_credits')"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <div v-if="vnz">
        <hr class="pz-dotted">
        <el-row
          :gutter="20"
          class="pz-large-margin"
        >
          <el-col
            :span="24"
            class="row justify-content-center"
          >
            <b class="pz-font-size-lg">
              {{ $t('forms_flujo.form2_persona.SP.venezolano.title') }}
            </b>
          </el-col>
          <el-col
            :span="24"
            class="row justify-content-center"
          >
            <label>
              {{ $t('forms_flujo.form2_persona.SP.venezolano.sub_title') }}
            </label>
          </el-col>
        </el-row>
        <el-row
          :gutter="20"
          class="pz-large-margin"
        />
        <el-row :gutter="20">
          <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
          >
            <pz-identity-document
              v-model="formulario2.identity"
              prop="identity"
              type-hidden
              :type-active=false
              type="person"
              :number-label="$t('forms_flujo.form1_persona.SP.CIV')"
            />
          </el-col>
          <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
          >
            <el-form-item
              prop="info_extra.tiempo_viviendo_espana"
              :label="$t('forms_flujo.form2_persona.SP.tiempo_viviendo_espana')"
              :error="errors.get('tiempo_viviendo_espana')"
            >
              <el-select
                ref="info_extra.tiempo_viviendo_espana"
                v-model="formulario2.info_extra.tiempo_viviendo_espana"
                clearable
                :placeholder="$t('forms_flujo.form2_persona.SP.tiempo_viviendo_espana')"
              >
                <el-option
                  v-for="item in tiempo_viviendo"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  :placeholder="$t('forms_flujo.form2_persona.SP.tiempo_viviendo_espana')"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
          >
            <el-form-item
              prop="estudios"
              :label="$t('forms_flujo.form1_persona.SP.studies')"
              :error="errors.get('estudios')"
            >
              <el-select
                v-model="formulario2.estudios"
                clearable
                :placeholder="$t('forms_flujo.form1_persona.SP.studies')"
              >
                <el-option
                  v-for="item in studies"
                  :key="item.id"
                  :label="item.estudio"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
          >
            <el-form-item
              prop="info_extra.institucion_de_estudio"
              :label="$t('forms_flujo.form1_persona.SP.institucion_de_estudio')"
              :error="errors.get('institucion_de_estudio')"
            >
              <el-autocomplete
                v-model="formulario2.info_extra.institucion_de_estudio"
                :fetch-suggestions="getSuggest"
                :placeholder="$t('forms_flujo.form1_persona.SP.institucion_de_estudio')"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
          >
            <el-form-item
              prop="info_extra.ultima_direccion_venezuela"
              :label="$t('forms_flujo.form2_persona.SP.ultima_direccion_venezuela')"
              :error="errors.get('ultima_direccion_venezuela')"
            >
              <el-input
                v-model="formulario2.info_extra.ultima_direccion_venezuela"
                tupe="text"
                :placeholder="$t('forms_flujo.form2_persona.SP.ultima_direccion_venezuela')"
              />
            </el-form-item>
          </el-col>
          <el-col
            :xl="{span: 12}"
            :lg="{span: 12}"
            :md="{span: 12}"
            :sm="{span: 24}"
            :xs="{span: 24}"
          >
            <el-form-item
              prop="info_extra.ultimo_trabajo_venezuela"
              :label="$t('forms_flujo.form2_persona.SP.ultimo_trabajo_venezuela')"
              :error="errors.get('ultimo_trabajo_venezuela')"
            >
              <el-input
                v-model="formulario2.info_extra.ultimo_trabajo_venezuela"
                tupe="text"
                :placeholder="$t('forms_flujo.form2_persona.SP.ultimo_trabajo_venezuela')"
              />
            </el-form-item>
          </el-col>


        </el-row>
      </div>
      <el-row
          :gutter="20"
          class="pz-large-margin"
      >
        <el-col :span="24"
                style="text-align: center;"
        >
          <el-form-item
                  prop="formato_documento"
                  :label="$t('forms_flujo.form2_persona.SP.formato_documento')"
                  :error="errors.get('formato_documento')"
          >
            <el-radio-group v-model="formulario2.formato_documento" >
              <el-radio-button  label="dni_plastico">
                DNI/NIE Plástico o Pasaporte
              </el-radio-button >
              <el-radio-button  label="tarjeta_roja">
                 Otra Documentación
              </el-radio-button >
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </pz-form-flujo>
</template>
<style>
    .el-radio-button__inner{
        height: 50px!important;
        text-align: center!important;
        display: flex!important;
        align-items: center!important;
        white-space: pre-wrap!important;
    }
    .el-radio-button{
        width: 39%!important;
        margin-right: 20px!important;
    }
</style>
<script>
//import {required, type} from "@/commons/utils/Rules";
import {isEmpty} from "@/commons/utils/functions";
import {
  getEstudios,
  getFormasDeEnterarse, getFormasPago, getPais,
  getRelacionesLaborales,
  getTipoViviendas,
  validateContact
} from "@/routes/api/resources";
import baseform2 from '../baseForm2'
import PzFormFlujo from "@/components/Flujo/Templates/PzFormFlujoTemplate";
import lowercase from "@/commons/filters/string/lowercase";
import {mapGetters} from "vuex";
import PzIdentityDocument from "@/commons/forms/Fields/PzIdentityDocument";
import {required, type} from "@/commons/utils/Rules";

export default {
  name: 'PzPersonFormExtEs',
  components: {PzIdentityDocument, PzFormFlujo},
  mixins: [baseform2],
  data() {
    var isNumberValidator = (rule, value, callback) => {
      if (/\D/.test(value)) {
        callback(new Error('Solo se permiten caracteres numericos'));
      } else {
        callback();
      }
    }
    var check_email = (rule, value, callback) => {
      if (this.formulario2.email_contacto === this.applicant.mail) {
        return callback(new Error(this.$t('rules.email_equal')));
      } else {
        validateContact(this.formulario2.email_contacto, null, this.applicant.identity.number).then((res) => {
          if (res.data.email_exists){
            return callback(new Error(this.$t('rules.email_exists')));
          } else {
            return callback();
          }
        }).catch(() => {
          return callback(new Error(this.$t('rules.email_exists')));
        });
      }
    };
    var check_phone = (rule, value, callback) => {
      if (value === this.applicant.telefono) {
        return callback(new Error(this.$t('rules.phone_equal')));
      }
      else {
          this.$store.dispatch('loader/up', {trigger: this.$options.name});
        validateContact(null, this.formulario2.telefono_persona_contacto, this.applicant.identity.number).then((res) => {
          if (res.data.phone_exists) {
            return callback(new Error(this.$t('rules.phone_exists')));
          } else {
            return callback();
          }
        }).catch(() => {
          return callback(new Error(this.$t('rules.phone_exists')));
        }).finally(()=>{
            this.$store.dispatch('loader/down', {trigger: this.$options.name})
        });
      }
    };
    return {
      employment_relations: [],
      studies: [],
      suggest: [],

      income_source: [
        {id: 'Ama de casa', name: 'Ama de Casa'},
        {id: 'Estudiante ', name: 'Estudiante'},
        {id: 'Empleado de la ONCE ', name: 'Empleado de la ONCE'},
        {id: 'Venta ambulante', name: 'Venta ambulante'},
        {id: 'Empleado sector agrario', name: 'Empleado sector agrario'},
        {id: 'Contrato fijo (público)', name: 'Contrato fijo (público)'},
        {id: 'Contrato temporal (público)', name: 'Contrato temporal (público)'},
        {id: 'Contrato fijo (privado)', name: 'Contrato fijo (privado)'},
        {id: 'Contrato temporal (privado)', name: 'Contrato temporal (privado)'},
        {id: 'Autónomo', name: 'Autónomo'},
        {id: 'Prejubilado', name: 'Prejubilado'},
        {id: 'Jubilado', name: 'Jubilado'},
        {id: 'Pensionista', name: 'Pensionista'},
        {id: 'Desempleado con prestación', name: 'Desempleado con prestación'},
        {id: 'Desempleado sin prestación', name: 'Desempleado sin prestación'}

      ],
      tiempo_viviendo: [
        {id: '0 - 1 Años', name: '0 - 1 Años'},
        {id: '1 - 3 Años', name: '1 - 3 Años'},
        {id: '3 - 5 Años', name: '3 - 5 Años'},
        {id: '5 - 10 Años', name: '5 - 10 Años'},
        {id: '10+ Años', name: '10+ Años'}
      ],
      cahs_in_list: [],
      vnz: false,
      properties: [],
      formas_de_enterarse: [],
      formulario2: {
        tipo_vivienda: '',
        relacion_laboral_id: '',
        estudios: '',
        cash_in_id: '',
        comoseentero: '',
        persona_contacto: '',
        telefono_persona_contacto: '',
        identity: {document: 4},
        info_extra: {
          institucion_de_estudio: '',
          fuente_ingreso_principal: '',
          ultima_direccion_venezuela: '',
          ultimo_trabajo_venezuela: '',
          has_others_credits: false,
          accepted_before: false,
          referenciado_por: '',
          tiempo_viviendo_espana: ''
        },
        email_contacto: '',
        formato_documento: ''
      },
      disable: true,
      rules_formulario2:
        {
          tipo_vivienda: [required('')],
          relacion_laboral_id: [required('')],
          comoseentero: [required('')],
          persona_contacto: [required('')],
          telefono_persona_contacto: [required(''), {validator: check_phone, trigger: 'blur'},{validator: isNumberValidator, trigger: 'blur'}],
          info_extra: {
            referenciado_por: [required('')]
          },
          email_contacto: [required(''), type({field: '', type: 'email'}), {validator: check_email, trigger: 'blur'}],
          formato_documento: [required('')],

        }

    };
  },
  computed: {
    ...mapGetters(['applicant'])
  },
  created() {
    this.$store.dispatch('loader/up', {trigger: this.$options.name});
    this.suggest = Object.values(this.$t('vnz_universities')).map((value) => {
      return {value: value}
    })
    // this.income_source = Object.values(this.$t('income_source')).map((value) => {
    //     return {value: value}
    // })
    this.fechtData().finally(() => {
      this.$store.dispatch('loader/down', {trigger: this.$options.name})
    });
  },
  methods: {
    getIncomen(queryString, cb) {
      let suggest = this.income_source;
      const self = this;
      let results = !isEmpty(queryString) && !isEmpty(suggest) ? suggest.filter(function (value) {
        return (self._.deburr(value.value).toLowerCase().indexOf(self._.deburr(queryString).toLowerCase()) >= 0);
      }) : suggest;
      cb(results);
    },
    getSuggest(queryString, cb) {
      let suggest = this.suggest;
      const self = this;
      let results = !isEmpty(queryString) && !isEmpty(suggest) ? suggest.filter(function (value) {
        return (self._.deburr(value.value).toLowerCase().indexOf(self._.deburr(queryString).toLowerCase()) >= 0);
      }) : suggest;
      cb(results);
    },
    fechtData() {
        this.$store.dispatch('loader/up', {trigger: this.$options.name});
      let viv = getTipoViviendas().then((res) => {
        this.properties = res.data;
      }).catch(() => {
        this.properties = [];
      });
      let est = getEstudios().then((res) => {
        this.studies = res.data;
      }).catch(() => {
        this.studies = [];
      });
      let re = getRelacionesLaborales().then((res) => {
        this.employment_relations = res.data;
      }).catch(() => {
        this.employment_relations = [];
      });
      let form = getFormasDeEnterarse().then((res) => {
        this.formas_de_enterarse = res.data;
      }).catch(() => {
        this.formas_de_enterarse = [];
      });
      let cahs_in_list = getFormasPago().then((res) => {
        this.cahs_in_list = res.data;
      }).catch(() => {
        this.cahs_in_list = [];
      });
      let country = getPais(this.applicant.pais_nacimiento).then((res) => {
        if (lowercase(res.data.iso).toString() === 've'.toString()) {
          this.vnz = true;
          this.formulario2.identity['document'] = 4;
        }
      }).catch((error) => {
        this.vnz = false;
        console.log(error)
      });
      return Promise.all([viv, re, form, est, country, cahs_in_list]).then(() => {
      }).finally(() => {this.$store.dispatch('loader/down', {trigger: this.$options.name})});
    }
  }
}
</script>

